html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

/* HEADER */

#header {
  top: 0;
  height: 10em;
  background: #F8F9FA 0% 0% no-repeat padding-box;
  position: fixed;
  width: 100%;
  z-index: 5;
  display: flex;
  flex-flow: row;
  align-items: center;
}

#falcBuilder #header {
  background-color: white;
}

#header .url::after {
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(255 255 255));
  content: '';
  width: calc(50% - 4px);
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  left: calc(50% + 2px);
  pointer-events: none;
}

#header .url {
  width: 40%;
  height: 3em;
  margin: 0 1.5em;
  position: relative;
}

#header .url .anchor {
  height: 3em;
  width: 3em;
  background-image: url(../images/anchor-gray.svg);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
}

#header .url input {
  width: 100%;
  height: 100%;
  padding-left: 3em;
  font: normal normal normal 1em/1em Inter;
  color: #404145;
  border: 0.5px solid #BAC4EA;
  border-radius: 5px;
  overflow: hidden;
}

#header #homeButton {
  display: flex;
  flex-flow: column;
  width: 3em;
  height: 3em;
  margin: 0 3em;
  cursor: pointer;
}

#header #homeButton p {
  margin: 0;
}

.editinfo {
  z-index: 0;
}

#falcBuilder .editinfo>p {
  color: #FEA160;
  background: #FFF7F2;
}

#simplified .editinfo>p {
  color: #7ABDFD;
  background: #EFF7FF;
}

.editinfo>p {
  margin: 0;
  width: max-content;
  font: normal normal 500 1.2em/1em Ubuntu;
  letter-spacing: 0px;
  padding: 1em 1em;
  border-radius: 0.2em;
  min-width: 13em;
  text-align: center;
}

#falcBuilder .editinfo>p {}

#simplified .editinfo>p {}

/* HOME */

#home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
}

#home #step1, #home #step2, #home #step3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  /* opacity: 0.16; */
}

.blue {
  color: #5680F5;
}

#home .wrapper {
  padding: 0 10em;
  width: 70em;
  border-radius: 0.6em;
  max-width: 80%;
  background: #F7F8FE 0% 0% no-repeat padding-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
}

.title2 {
  font: normal normal 500 2em/1em Ubuntu;
  color: #1B2026;
}

#welcome {
  margin: 0;
  padding: 2.6em;
}

#home .info {
  font: normal normal 500 1.25em/1em Ubuntu;
  color: #2B2B2E;
  align-self: flex-start;
  margin: 4em 0 3em 0;
}

#welcome .logo {
  font: normal normal bold 1em/1em Comfortaa;
  letter-spacing: 0px;
  color: #5680F5;
}

#home #step1 {
  width: 100%;
  /* margin-top: 2em; */
}

#home #step1 .input {
  width: 100%;
  height: 50px;
}

#home #step1 .input input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 0.4em;
  padding-left: 4em;
}

#home #step1 .input input {
  width: 100%;
  font-size: 11pt;
}

#home #step1 .input .anchor {
  height: 50px;
  width: 50px;
  background-image: url(../images/anchor_blue.svg);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
}

#home #step2 {
  width: 100%;
  display: flex;
  flex-flow: row;
}

#home #step2 .spacer {
  width: 10%;
}

#home #step2 .option {
  background: #ffffff;
  padding: 1em 3em 1em 3em;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 11em;
}

#home #step2 .option label {
  font: normal normal 500 1em/1em Ubuntu;
  letter-spacing: 0px;
}

#home #step2 .description {
  margin-top: 1em;
  font: normal normal normal 0.9em/1.5em Inter;
}

#home #step2 .option input[type="radio"] {
  position: absolute;
  top: 1.2em;
  left: 1.2em;
  width: 16px;
  height: 16px;
}

#home #step2 .option:hover input[type="radio"]:not(:checked)::after {
  border-radius: 50%;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 25%;
  left: 25%;
  content: " ";
  background: lightgray;
  padding: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home #step3 button {
  width: 9em;
  height: 3em;
  border: none;
  background: #5680F5;
  font: normal normal 500 1.5em/1em Ubuntu;
  color: #F8F9FB;
  border-radius: 0.4em;
  margin-top: 4em;
  margin-bottom: 3em;
  cursor: pointer;
}

/* SIMPLIFIED EDITOR */

#simplified {
  width: 100%;
  height: 100%;
}

#simplified #iframe {
  /* width: 60%; */
  width: 60%;
  height: 100%;
  border: 0;
  outline: 0;
  border-right: 3px solid #b8bbc0;
  padding-top: 10em;
}

#simplified #iframe::-webkit-scrollbar {
  display: none;
}

#simplified #propositions {
  width: 40%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  background: #F5F8FD;
  margin-top: 10em;
}

#simplified #propositions .add {
  position: absolute;
  width: 60px;
  left: -30px;
  margin-top: -30px;
  cursor: pointer;
}

#simplified #propositions .simplification .myeditor p {
  margin-bottom: 0;
}

#simplified #propositions .simplification .myeditor {
  height: 100%;
  width: 100%;
  font-weight: normal;
  max-height: 10em;
  padding: 0 1em;
}

#simplified #propositions .simplification .simplificationContainer {
  position: absolute;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #E9EAF0CE;
  border-radius: 0.5em;
  width: calc(90% - 60px);
  left: 2.5em;
  min-height: 2em;
  transform: translateY(-50%);
  font-size: 1.5em;
  padding-left: 1em;
}

#simplified #propositions .simplification .simplificationContainer>div:first-child {
  max-height: 10em;
  overflow: auto;
}

#simplified #propositions .simplification {
  position: absolute;
  width: 100%;
}

#simplified #propositions .simplification .actions {
  position: absolute;
  bottom: -60px;
  width: 120px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  z-index: 99;
}

#simplified #propositions .simplification .actions img {
  width: 50%;
  margin: 5%;
  border-radius: 50%;
  cursor: pointer;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 8px #E9EAF0CE;
  border-radius: 0.4em;
}

/* FALC EDITOR */

#falcBuilder {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  position: relative;
}

.end .close {
  position: absolute;
  top: 1em;
  right: 1em;
  color: #5A5A5A;
  cursor: pointer;
}

.end {
  z-index: 1000;
  width: 70em;
  height: 40em;
  background: #F7F8FE;
  position: fixed;
  right: 150px;
  border-radius: 1em;
  display: flex;
  top: 3em;
  right: 14em;
}

.blur {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: black;
  z-index: -1;
  opacity: 0.8;
}

.end .row1 {
  display: flex;
  width: 45%;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5%;
  padding-bottom: 0;
}

.end .row1 .input {
  width: 100%;
  position: relative;
  margin-top: 2em;
}

.end .row1 .input input {
  padding-left: 3em;
  width: 100%;
  height: 3.5em;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.6000000238418579px solid #BAC4EA;
  border-radius: 5px;
}

.end .row1 .input::before {
  content: '';
  width: 1.5em;
  height: 1.2em;
  position: absolute;
  top: 1.25em;
  left: 1em;
  z-index: 1;
  background: url(../images/email-2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.end .row1 h3 {
  font: normal normal bold 1.5em/1em Arial;
  color: #2E81EF;
}

.end .row1 h3:nth-child(2) {
  color: #2C2C2C;
}

.end .row1 p {
  font: normal normal normal 1em/1em Inter;
  color: #404145;
  line-height: 1.7em;
  margin-top: 2em;
}

.end .row2 {
  width: 55%;
  padding: 5%;
  padding-right: 8%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  position: relative;
}

.end .row2 p {
  text-align: left;
  font: normal normal bold 12px/20px Arial;
  letter-spacing: 0.48px;
  color: #2C2C2C;
}

.end .sendMail p {
  margin: 0;
  font: normal normal 500 1em/1em Ubuntu;
  color: #F8F9FB;
  margin-left: 0.4em;
}

.end .sendMail {
  width: fit-content;
  height: 3em;
  cursor: pointer;
  opacity: 0.3;
  pointer-events: none;
  background-color: #5680F5;
  display: flex;
  align-items: center;
  padding-left: 1em;
  justify-content: center;
  padding-right: 1em;
  border-radius: 0.4em;
  margin-left: auto;
  margin-top: 2em;
  cursor: pointer;
}

.end .sendMail.active {
  opacity: 1;
  pointer-events: all;
  position: relative;
}

/* email */

.end .row2 img {
  position: absolute;
  bottom: 0;
  right: 5%;
  width: 22em;
}

.end .row2 .input .anchor {
  height: 50px;
  width: 50px;
  background-image: url(../images/email.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  background-color: #EAF3FF;
  border-radius: 25px;
  box-shadow: 0px 5px 7px #E1E4F0;
}

.deleteRow {
  top: 0.5em;
  left: -3em;
  height: 3em;
  width: 1em;
  background-image: url(../images/TRASH.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  cursor: pointer !important;
}

.custom-drag-handle {
  top: 1.2em;
  left: -1em;
  height: 1.6em;
  width: 1em;
  background-image: url(../images/move.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  cursor: pointer !important;
}

.custom-drag-handle-image {
  position: absolute;
  left: -30px;
  height: 20px;
  width: 20px;
  background-color: purple;
}

.document {
  /* padding: 10px; */
  width: 40%;
  height: fit-content;
  padding-top: 2em;
  margin-top: 10em;
}

.document.dragging {
  /* background: lightblue; */
}

.element {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  margin-top: 0;
  margin-bottom: 55px;
  /* width: 100% !important; */
}

.element.hasImage.big {
  min-height: 200px;
}

.element.hasImage.small {
  min-height: 100px;
}

.element.dragging {
  opacity: 0.8;
}

.title, .subtitle, .paragraph, .paragraphTitle {
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
  align-self: flex-start;
  padding: 1em;
  padding-left: 2em;
}

textarea {
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  resize: none;
  line-height: 1em;
}

.title textarea {
  font: normal normal bold 2em/1.2em Arial;
  letter-spacing: 0.05em;
  color: #3F454E;
}

.subtitle textarea {
  font: normal normal bold 1.5em/1em Arial;
  color: #404145;
}

.paragraph, .paragraph::placeholder {
  font: normal normal normal 1.2em/1.9em Arial;
  color: #000;
}

/* .title textarea::placeholder, .subtitle textarea::placeholder, .paragraph textarea::placeholder {
  line-height: 1em;
} */

.myeditor {
  /* padding: 10px; */
  font-weight: bold;
  font-family: arial;
  line-height: 2em;
  overflow: auto;
  font-size: 16px;
}

.myeditor:focus {
  outline: none;
}

.medium-editor-toolbar-form.medium-editor-toolbar-form-active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-link {
  color: green;
  font-size: 40px;
}

.medium-editor-toolbar-close {
  order: -1;
}

.close-link {
  color: white;
  font-size: 40px;
}

.medium-editor-toolbar-input::before {
  content: 'face'
}

.medium-editor-toolbar-input {
  font-size: 1.2em;
}

/* upload */

.ant-upload-picture-card-wrapper, .ant-upload-list-picture-card-container, .ant-upload-list, .ant-upload-list-item, .ant-upload {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* insert image */

#falcBuilder.preview .insert-image {
  right: 100%;
}

.insert-image {
  align-self: flex-start;
  position: absolute;
  float: left;
  right: calc(100% + 6em);
  top: 1em;
  width: 100px;
  height: 100px;
  /* border: 1px dashed black; */
}

.insert-image.hasImage {
  width: fit-content;
  height: fit-content;
}

.insert-image.dragging {
  width: 100px;
  height: 100px;
}

.sideBar .insert-image {
  display: none;
}

.document .insert-image>img {
  /* position: absolute; */
  right: 0;
}

.document .insert-image .small {
  width: 40px;
  height: 40px;
  position: relative;
  margin-left: auto;
}

/* .document .small {
  width: 250px;
  height: 250px;
} */

.document .insert-image .big {
  width: 120px;
  height: 120px;
  position: relative;
}

/* .document .big {
  width: 500px;
  height: 500px;
} */

.divider {
  color: white;
  width: 100%;
  font-size: 18px;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}

.imagePlaceholder {
  width: 100px;
  height: 100px;
  /* border: 1px solid #707070; */
  opacity: 1;
}

.uploadButton {
  background: #2C2C2C;
  width: 100%;
  height: 100%;
  padding: 10%;
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: center;
  justify-content: space-around;
}

.insert-image .big .uploadButton {
  padding: 20%;
}

.insert-image .big .uploadButton img {
  /* padding: 20%; */
  width: 50%;
}

.insert-image .small .uploadButton img {
  /* padding: 20%; */
  width: 100%;
}

.small .uploadButton h3 {
  display: none;
}

.uploadButton h3 {
  color: white;
  font-size: 1em;
  margin: 0;
}

.insert-image.dragging .imagePlaceholder {
  background: blue;
  opacity: 1;
}

/*  */

.itemWrapper {
  width: 100%;
  height: 100%;
  /* justify-content: center; */
  justify-content: left;
  display: flex;
  /* align-items: center; */
}

/* BUTTONS */

#buttons {
  padding: 0;
  margin: 3em;
  z-index: 1;
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
}

#buttons .button {
  display: flex;
  align-items: center;
  flex: 1;
  display: block;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 3em;
  width: 9em;
}

#buttons .button img {
  height: 0.8em;
  margin-right: 0.4em;
}

#buttons .button h3 {
  font: normal normal 500 1em/1em Ubuntu;
  margin: 0;
}

#end h3 {
  color: white;
}

#end, #preview {
  cursor: pointer;
}

#end {
  background-color: #5680F5;
  border-radius: 0.5em;
}

#end.active {
  background-color: #FFFFFF;
  color: #5680F5;
  border: 0.2em solid #5680F5;
}

#end.active h3 {
  color: #5680F5;
}

#preview h3 {
  color: #5680F5;
}

/* PREVIEW */

.preview .title, .preview .subtitle, .preview .paragraph, .preview .paragraphTitle {
  border: none;
  box-shadow: none;
}

.preview .imagePlaceholder, .preview .deleteRow, .preview .custom-drag-handle {
  display: none;
}

.preview .element {
  margin: 0;
}

/* THANK YOU */

#thankyou {
  width: 100%;
  height: 100%;
  background: #F5F8FD;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: column;
}

#thankyou .text {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: column;
  padding-top: 5%;
  text-align: center;
}

#thankyou .text h2 {
  color: #2E81EF;
  font: normal normal bold 22pt/25pt Arial;
}

#thankyou .text p {}

#thankyou .text p:nth-child(2) {
  font: normal normal normal 16pt/22pt Arial;
}

#thankyou .text p:nth-child(3) {
  font: normal normal bold 14pt/21pt Arial;
  color: #2E81EF;
}

#thankyou .text p:nth-child(4) {
  font: normal normal normal 14pt/21pt Arial;
}

#thankyou .img {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

#thankyou .img img {
  width: 70%;
  margin-top: auto;
  justify-self: flex-end;
}

#thankyou #goback {
  position: fixed;
  top: 30pt;
  left: 50pt;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

#thankyou #goback img {
  margin-right: 10pt;
}

#thankyou #goback p {
  margin: 0;
  font: normal normal bold 13pt/22pt Arial;
}

/* add item */

#addItem {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  background-color: #F7F8FE;
  border-radius: 0.6em;
  margin-bottom: 8em;
  left: 2em;
}

#addItem .add.active, #addItem .add:hover {
  background: url(../images/AADD.svg) no-repeat center;
}

#addItem .add {
  background: url(../images/AADD-gray.svg) no-repeat center;
  top: 0;
  left: -4em;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  cursor: pointer;
}

#addItem .add:not(.active):hover:after {
  content: 'Ajouter un élément';
  width: max-content;
  margin-left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -2em;
  font-size: 0.9em;
  color: #5680f5;
}

#addItem>div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-flow: column;
}

#addText .option {
  width: calc(100% - 20pt);
  margin: 2pt 5pt;
  height: 30pt;
  display: flex;
  align-items: center;
  border-radius: 5pt;
  padding-left: 5pt;
}

#addText .option:hover {
  background: white;
}

#addItem #addText {
  flex: 0.3;
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
  justify-content: space-between;
  justify-content: space-between;
}

#addItem #addPicture {
  flex: 0.7;
}

#addItem h4 {
  font: normal normal 500 1em/1em Ubuntu;
  color: #5680F5;
  margin-bottom: 3em;
  position: relative;
  width: 100%;
}

#addItem h4::after {
  content: '';
  border-bottom: 3px solid #5680F5;
  position: absolute;
  bottom: -0.7em;
  width: calc(100% - 2em);
  left: 0;
}

#addItem ._title {
  font: normal normal bold 1.9em/30px Inter;
  letter-spacing: 1px;
  color: #3F454E;
  cursor: pointer;
}

#addItem ._subtitle {
  font: normal normal bold 1.3em/26px Arial;
  letter-spacing: 0.88px;
  color: #595D64;
  cursor: pointer;
}

#addItem ._paragraph {
  font: normal normal normal 1em/40px Arial;
  letter-spacing: 0.72px;
  color: #000000;
  cursor: pointer;
}

#addItem #addPicture .options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#addItem #addPicture .options .option {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3 - 2em);
}

#addItem #addPicture img:hover {
  box-shadow: 0 0 0 1.5pt #5680F5;
}

#addItem #addPicture img {
  width: 100%;
  cursor: pointer;
  margin-top: 1em;
  border-radius: 5pt;
}

#addItem #addPicture h5 {
  font: normal normal 500 1em/1em Ubuntu;
  color: #7C7E84;
  align-self: flex-start;
}

#addItem #addPicto img {
  width: 5em;
  cursor: pointer;
}

/* TIPTAP EDITOR  */

.bubble-menu {
  border: 1px solid black;
  display: flex;
  background: white;
  border-radius: 0.5em;
}

.bubble-menu img {
  height: 2em;
  padding: 0.5em;
  opacity: 0.5;
}

.bubble-menu img.is-active {
  opacity: 1;
}

.floating-menu {
  width: 12em;
  display: flex;
  justify-content: space-around;
  height: 2em;
}

.floating-menu img {
  flex: 1;
  opacity: 0.5;
  object-fit: scale-down;
}

.floating-menu img.is-active {
  opacity: 1;
}

.ProseMirror:focus {
  outline: none;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  #home {
    font-size: 0.85em;
  }
}